<!-- cv Audio -->
<template>
  <div data-app>
    <div class="row">
      <div class="col-md-8">
        <div class="col-12 row m-0 p-0 bg-white">
          <div class="col-6 py-4 px-4 border_b_silver">
            <span class="card-label font-weight-bolder Main_Blue">Audios</span>
          </div>
          <div class="col-6 p-0 pt-3 text-end border_b_silver pr-2">
            <button
              type="button"
              class="custom-add-new-record-button btn_dash_ch mr-2"
              fab
              small
              @click="createFormEnable"
            >
              <span class="v-btn__content" style="font-size: 14px !important"
                >Add new Audio</span
              >
            </button>
          </div>
        </div>
        <v-data-table
          :headers="headers"
          :items="data"
          item-key="title"
          class="rounded-0 table-borderless px-4 headers_job_function_ch"
        >
          <template v-slot:item="props">
            <tr class="border__ch mb-4">
              <td
                class="font-weight-bolder Main_Blue"
                style="font-size: 14px; width: 35%"
              >
                {{ props.item.title }}
              </td>
              <td
                class="font-weight-bolder Main_Blue"
                style="font-size: 12px; font-weight: 500 !important; width: 45%"
              >
                {{
                  moment(props.item.created_at).format(
                    "MMMM Do YYYY, h:mm:ss a"
                  )
                }}
              </td>
              <td
                class="pt-3 border-0"
                style="display: flex; flex-direction: row"
              >
                <v-btn
                  class="py-2 ch_btn_silver_rounded_0"
                  fab
                  small
                  @click="downloadAudio(props.item)"
                >
                  <img
                    src="\images/job_application/Download_icon.svg"
                    alt=""
                    width="16"
                    height="16"
                  />
                </v-btn>
                <v-btn
                  class="py-2 ch_btn_silver_rounded_0"
                  fab
                  small
                  @click="playAudio(props.item)"
                >
                  <img
                    src="\images/job_application/Play_icon.svg"
                    alt=""
                    width="16"
                    height="16"
                  />
                </v-btn>
                <v-btn
                  class="py-2 ch_btn_silver_rounded_0"
                  fab
                  small
                  @click="editButtonClick(props.item)"
                >
                  <img
                    src="\images/job_application/Edit_Icon.svg"
                    alt=""
                    width="16"
                    height="16"
                  />
                </v-btn>
                <v-btn
                  class="py-2 ch_btn_silver_rounded_0"
                  fab
                  small
                  @click="askUserAboutDelete(props.item)"
                >
                  <img
                    src="\images/job_application/Delete.svg"
                    alt=""
                    width="16"
                    height="16"
                  />
                </v-btn>
              </td>
            </tr>
            <tr v-bind:key="i">
              <td style="height: 12px" colspan="12"></td>
            </tr>
          </template>
        </v-data-table>
      </div>
      <div class="col-md-4 pl-0">
        <div style="height: 100%" class="bg-white p-4 ch_hight">
          PlaseHolder Image
        </div>
      </div>
      <div class="col-md-4">
        <!-- create form start -->
        <v-form ref="form" v-if="createForm" v-model="valid" lazy-validation>
          <v-text-field
            v-model="title"
            :rules="nameRules"
            label="title"
            required
          ></v-text-field>

          <v-file-input
            style="font-size: 8px"
            label="Choose your short intro audio"
            @change="getDuration"
          >
          </v-file-input>
          <label>Note: Please upload Audio not more than 30 seconds</label>
          <v-btn
            class="
              btn btn-sm btn-success
              font-weight-bold
              py-2
              px-3 px-xxl-5
              my-1
            "
            @click="createIntroAudio"
            :disabled="
              title === '' || (title === null && audio === '') || audio === null
                ? true
                : false
            "
            ><b-spinner small type="grow" v-if="submitting"></b-spinner>
            {{ submitting ? "Please wait, Audio uploading" : "Submit" }}
          </v-btn>
          <v-btn
            style="margin-left: 6%"
            class="
              btn btn-sm btn-danger
              font-weight-bold
              py-2
              px-3 px-xxl-5
              my-1
            "
            @click="cancel"
          >
            Cancel
          </v-btn>
        </v-form>
        <!-- create form end -->

        <!-- update form start -->
        <v-form ref="form" v-if="updateForm" v-model="valid" lazy-validation>
          <v-text-field
            v-model="title"
            :rules="nameRules"
            label="title"
            required
          ></v-text-field>

          <v-file-input
            style="font-size: 8px"
            label="Update your short intro Audio"
            v-model="audio"
          >
          </v-file-input>
          <label>Note: Please upload audio not more than 30 seconds</label>
          <v-btn
            class="
              btn btn-sm btn-success
              font-weight-bold
              py-2
              px-3 px-xxl-5
              my-1
            "
            @click="updateIntroAudio"
            :disabled="title === '' || title === null ? true : false"
            ><b-spinner small type="grow" v-if="submitting"></b-spinner>
            Update
          </v-btn>
          <v-btn
            style="margin-left: 6%"
            class="
              btn btn-sm btn-danger
              font-weight-bold
              py-2
              px-3 px-xxl-5
              my-1
            "
            @click="cancel"
          >
            Cancel
          </v-btn>
        </v-form>
        <video-player
          v-if="activeAudio !== ''"
          class="video-player-box"
          ref="videoPlayer"
          :options="playerOptions"
          :playsinline="true"
          customEventName="customstatechangedeventname"
        >
        </video-player>
      </div>
    </div>
    <!-- Confirm Delete Item -->

    <v-row justify="center">
      <v-dialog v-model="dialog" persistent max-width="380px">
        <v-card>
          <v-card-title>
            <span class="headline"
              >Do you really want to DELETE this item?</span
            >
          </v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="custom-submit-button" text @click="dialog = false"
              >No</v-btn
            >
            <v-btn class="custom-cancel-button" text @click="deleteItem"
              ><b-spinner small type="grow" v-if="deleting"></b-spinner
              >Delete</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>
<script>
import axios from "axios";
import { API_URL, MEDIA_URL } from "@/core/api";
import Swal from "sweetalert2";
import JobSeekerService from "@/MainServices/JobSeekerService.js";
import moment from "moment";
// import Vue from 'vue';
// import VueAudio from 'vue-audio-better';
// import "audio.js/dist/audio-js.css";
// import "audio.js/dist/audio-js.css";
// import { audioPlayer } from "vue-audio-player";

export default {
  data() {
    return {
      mediaUrl: MEDIA_URL,
      loading: true,
      dialog: false,
      deleteId: null,
      deleting: false,
      success: false,
      submitting: false,
      token: `Bearer ${localStorage.getItem("token")}`,
      valid: true,
      createForm: false,
      updateForm: false,
      title: "",
      Audio: "",

      cvId: null,
      nameRules: [
        (v) => !!v || "Audio title is required",
        (v) =>
          (v && v.length <= 20) ||
          "Audio title must be less than 20 characters",
      ],
      headers: [
        { text: "Title", value: "title" },
        { text: "Added On", value: "created_at" },
        { text: "Actions", value: "Action" },
        // { text: "Audio", value: "path" },
        // { text: "Action", value: "action" },
      ],
      data: [],
      activeAudio: "",
      playerOptions: {
        // Audiojs options
        muted: true,
        language: "en",
        playbackRates: [0.7, 1.0, 1.5, 2.0],
        sources: [
          {
            type: "audio/mp3",
            src: this.activeAudio,
          },
        ],
        poster: "/static/images/author.jpg",
      },
    };
  },
  mounted() {
    this.getInititalData();
  },
  // components: {
  //   audioPlayer,
  // },
  // computed: {
  //   player() {
  //     return this.$refs.audioPlayer.player;
  //   },
  // },
  methods: {
    moment: function () {
      return moment();
    },
    getInititalData() {
      JobSeekerService.getMyAudios()
        .then((res) => {
          this.data = res.data;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },

    getDuration(item) {
      this.audio = item;
    },
    cancel() {
      this.dialog = false;
      this.createForm = false;
      this.updateForm = false;
      this.title = "";
      this.audio = "";
      this.loading = false;
      this.success = true;
      this.submitting = false;
    },
    createFormEnable() {
      this.career_level = "";
      this.createForm = true;
      this.updateForm = false;
    },
    createIntroAudio() {
      if (!this.submitting) {
        if (this.audio == "" || this.title == "") {
          Swal.fire({
            title: "",
            text: "Please, provide correct data!",
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
          return;
        }
        this.submitting = true;
        let formData = new FormData();
        formData.append("title", this.title);
        formData.append("audio", this.audio);
        axios.defaults.headers.common["Authorization"] = this.token;
        axios
          .post(`${API_URL}/job-seeker-store-intro-audio`, formData)
          .then((res) => {
            this.cancel();
            this.getInititalData();
            Swal.fire({
              title: "",
              text: res.data,
              icon: "success",
              confirmButtonClass: "btn btn-secondary",
              heightAuto: false,
            });
          })
          .catch(() => {
            this.loading = false;
            this.submitting = false;
            Swal.fire({
              title: "",
              text: "Something went wrong!",
              icon: "error",
              confirmButtonClass: "btn btn-secondary",
              heightAuto: false,
            });
          });
      }
    },
    updateIntroAudio() {
      if (!this.submitting) {
        if (this.title == "") {
          Swal.fire({
            title: "",
            text: "Please, provide correct data!",
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
          return;
        }
        this.submitting = true;
        let formData = new FormData();
        formData.append("cvId", this.cvId);
        formData.append("title", this.title);
        if (this.audio !== "") {
          formData.append("audio", this.audio);
        }

        axios.defaults.headers.common["Authorization"] = this.token;
        axios
          .post(
            `${API_URL}/job-seeker-update-intro-audio/${this.cvId}`,
            formData
          )
          .then((res) => {
            this.cancel();
            this.getInititalData();
            Swal.fire({
              title: "",
              text: res.data,
              icon: "success",
              confirmButtonClass: "btn btn-secondary",
              heightAuto: false,
            });
          })
          .catch(() => {
            this.loading = false;
            this.submitting = false;
            Swal.fire({
              title: "",
              text: "Something went wrong!",
              icon: "error",
              confirmButtonClass: "btn btn-secondary",
              heightAuto: false,
            });
          });
      }
    },
    changeStatus(item) {
      axios.defaults.headers.common["Authorization"] = this.token;
      axios
        .get(`${API_URL}/job-seeker-set-cv-default/${item.id}`)
        .then((res) => {
          this.loading = false;
          this.success = true;

          Swal.fire({
            title: "",
            text: "Status updated",
            icon: "success",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
          this.getInititalData();
          this.cancel();
        })
        .catch(() => {
          this.loading = false;
          Swal.fire({
            title: "",
            text: "Something went wrong!",
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
        });
    },
    editButtonClick(item) {
      this.createForm = false;
      this.updateForm = true;
      this.title = item.title;
      this.cvId = item.id;
    },
    downloadAudio(item) {
      window.open(this.mediaUrl + "videos/raw/" + item.path);
    },
    playAudio(item) {
      this.playerOptions.sources[0].src =
        this.mediaUrl + "videos/raw/" + item.path;
      this.activeAudio = "1";
    },

    deleteButtonClick(item) {
      JobSeekerService.deleteMyAudio(item)
        .then((res) => {
          Swal.fire({
            title: "",
            text: res.data,
            icon: "success",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
          this.getInititalData();
          this.cancel();
        })
        .catch(() => {
          Swal.fire({
            title: "",
            text: "Something went wrong!",
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
          this.loading = false;
        });
    },
    askUserAboutDelete(item) {
      this.deleteId = item.id;
      this.dialog = true;
    },

    deleteItem() {
      this.deleteButtonClick(this.deleteId);
    },
  },
};
</script>


<style>
.container {
  max-width: none !important;
}
</style>